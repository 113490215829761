@font-face {
  font-family: "KZ Supercell Magic";
  src: url("./fonts/kz-supercell-magic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  line-height: normal;
}

img {
  max-width: 100%;
}

body {
  background-color: #1a1e25;
  color: #fff;
  line-height: 1;
}

.app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding: 0 15px;
}

.wrapper {
  max-width: 600px;
  width: 100%;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;

  img {
    margin-bottom: 24px;
  }
}

.bt {
  width: 100%;
  height: 50px;
  background: #0083e3;
  border: 1px solid #2d3238;
  border-radius: 8px;
  font-family: "KZ Supercell Magic", sans-serif;
  color: #fff;
  padding-top: 6px;
  text-shadow: 1px 1px 0px #1d1f25;
  cursor: pointer;
  text-transform: uppercase;
}

.list-for-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;

  & > img {
    margin-bottom: 24px;
  }
}

.tit {
  h3 {
    font-family: "KZ Supercell Magic", sans-serif;
    background: linear-gradient(180deg, #68ccff 0%, #2e7cf5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &.green {
    h3 {
      background: linear-gradient(180deg, #a3d584 0%, #5c9637 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.descr {
  margin: 30px 0 44px;
  text-align: center;
  font-size: 14px;
  color: #8e97a8;

  p {
    margin-bottom: 10px;
  }
}

.form-spn {
  float: left;
  width: 100%;
  text-align: center;
  .tit {
    margin-bottom: 10px;
  }
  .inp {
    margin-bottom: 30px;
    float: left;
    width: 100%;
    span {
      float: left;
      width: 100%;
      background: #1d1f25;
      border: 1px solid #2d3238;
      border-radius: 8px;
      height: 50px;
      background-color: #1d1f25;
      font-family: "KZ Supercell Magic", sans-serif;
      padding: 0 15px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      overflow: visible;
      line-height: 52px;
    }
  }
}
